import React, { useEffect } from 'react';
const SwapComponent = () => {
    useEffect(() => {
        if (window.Jupiter) {
            window.Jupiter.init({
                displayMode: 'widget', // or 'widget' or 'modal'
                integratedTargetId: 'jupiter-terminal',
                endpoint: process.env.REACT_APP_SOLANA_RPC_ENDPOINT, // Using .env variable
            });
        }
    }, []);
    return <div id="jupiter-terminal" style={{ fontFamily: "Inter,sans-serif", zIndex: "2000" }}></div>;
};

export default SwapComponent;

import React from "react";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import './Home.css';
const Home = () => {
  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-text-section">
          <Typography
            sx={{
              color: "white",
              fontFamily: "Kids, sans-serif",
              fontSize: "160px",
              transform: "rotate(-6deg)",
              transition: "background-color 0.3s ease, transform 0.3s ease, font-size 0.3s ease",
              lineHeight: 1.6, // Adjust line spacing here
              marginBottom: "20px",

              '@media (max-width: 1200px)': {
                textAlign: "center",
                fontSize: "10vh",

              },
              '@media (max-width: 800px)': {
                fontSize: "7vh",
                lineHeight: 2,
                textAlign: "center",
                transform: "rotate(-6deg) translateY(-60%) translateX(3%)"
              }
            }}
          >
            $HERMY
            <Typography
              component="span"
              sx={{

                textAlign: "left",
                width: "549px",
                fontFamily: "Kids, sans-serif",
                fontSize: "40px",
                display: "block",
                lineHeight: 1.4, // Adjust line spacing here
                '@media (max-width: 1000px)': {
                  width: "270px",
                  fontSize: "20px",
                  textAlign: "center",
                },
                '@media (max-width: 800px)': {
                  textAlign: "center",
                  transform: "translateY(-30%) translateX(20%)"
                }
              }}
            >
              THE DEGENERATE STALLION
            </Typography>
          </Typography>

          <div class="buy-button-container">
            <a href="#" class="buy-button" id="home">BUY $HERMY</a>
          </div>
        </div>
      </div>

    </div>
  );
};



export default Home;

import React from 'react'
import './PFPGenerator.css'
import { FiArrowRight } from "react-icons/fi";
import GraySolid from '../Assets/graybg.jpg'
import PFPImage from '../Assets/pfpf.png'
const PFPGenerator = () => {
    return (
        <div className='pfp-section-container' id='pfp'>
            <div className='pfp-image-container'>
                <img className src={PFPImage}></img>
            </div>
            <div className='pfp-text-container'>
                <div className="header-designer-ultra-smart-bb-gg">HERMY PFP GENERATOR</div>
                <br />
                <p>Create your own  PFP in just 10 seconds! </p>
                <br />
                <p>Instant profile pics, banners, and wallpapers – everything you need to own social media.</p>
                <br />
                <p>
                    Spread the $Hermy vibe and let’s take over the internet! </p>
                <br />
                <div class="buy-button-container1">
                    <a href="https://pfphermy.vercel.app/" class="buy-button3">GENERATE A PROFILE PICTURE </a>
                </div>
            </div>
        </div>
    )
}

export default PFPGenerator
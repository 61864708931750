import React from "react";
import AboutBackgroundImage from "../Assets/hermy9-wbg.png";
import { FiArrowRight } from "react-icons/fi";
import './About.css'
import './WhatTheHeck.css'

const WhatTheHeck = () => {
    return (
        <div className="what-section-container">
            <div className="vibee-text">
                <div className="header-designer-ultra-smart-bb-gg">WHAT IS HERMY COIN?</div>
                <div className="text">
                    <br />
                    $Hermy is the first memecoin that mixes luxury with crypto!
                    <br />
                    <br />
                    Play lottery games on our Telegram app to win high-end items.
                    <br />
                    <br />
                    Shop our marketplace where $Hermy gets you luxury goods at incredible prices.
                    <br />
                    <br />
                </div>
                <br />
                <div className="header-designer-ultra-smart-bb-gg">LUXURY MEETS MEMES—GET $HERMY</div>
            </div>
            <br />
        </div>
    );
}

export default WhatTheHeck;

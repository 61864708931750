import React from 'react'
import styled from 'styled-components'
import Feature1 from '../Assets/features/key-features01.png'
import Feature2 from '../Assets/features/key-features02.png'
import Feature3 from '../Assets/features/key-features03.png'
const Container = styled.div`
    display: flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding:130px 80px;
`
const TitleContainer = styled.div`
    display:flex;
    align-items:center;
    text-align:center;
    margin-bottom:30px;
    h1{
        font-size:44px;
    }
`
const FeaturesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap:100px;
    @media (max-width:800px) {
        flex-direction: column;
        gap: 20px;
    }
`
const FeatureContainer = styled.div`
    img {
        min-width:220px;
        width: 428px;
        height:auto;
    }
    @media (max-width:800px) {
        width: 350px;
    }
`

const Features = () => {
    const redirect = (link) => {
        window.location.href = link;
    }
    return (
        <Container>
            <TitleContainer>
                <h1>
                    KEY FEATURES <span className="hide-small">OF HERMY COIN</span>
                </h1>
            </TitleContainer>
            <FeaturesWrapper>
                <FeatureContainer>
                    <img src={Feature1} onClick={() => redirect("https://t.me/Scratch2Winbot")} />
                </FeatureContainer>
                <FeatureContainer>
                    <img src={Feature2} onClick={() => redirect("https://t.me/Scratch2Winbot")} />
                </FeatureContainer>
                <FeatureContainer>
                    <img src={Feature3} onClick={() => redirect("https://t.me/Scratch2Winbot")} />
                </FeatureContainer>
            </FeaturesWrapper>
        </Container>
    )
}

export default Features
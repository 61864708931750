import React from 'react';
import styled from 'styled-components';
import { PieChart } from 'react-minimal-pie-chart';
import chartPicture from '../Assets/chart.png'
const MadnomicsContainer = styled.div`
  background-color: transparent;

  padding: 30px;
  display: flex;
  flex-direction: column;
  text-align: center; 
  min-height: 100vh; /* Full viewport height */

  .madnomics-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .first-section{
      justify-content: space-around;
    }

    .second-section{
      align-items: center;
    }

    .third-section{
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: space-between; /* Ensures space between elements */
      height: 70vh;
      span{
        color: white;
        text-align:left;
      }

      .contract{
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Align items to the left */
        width: 100%;
        @media (max-width:800px) {
          align-items: center;
          font-size:20px;
          span {
            font-size: 1.1rem;
          }
        }
        span {
          color: white;
          
          margin-bottom: 10px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .madnomics-container{
      flex-direction: column;
      justify-content: space-between;
    }

    .third-section {
      display: flex;
      flex-direction: row !important;
      justify-content: space-between; /* Make elements inline */
      align-items: center;
      height: 30vh !important;
      margin-left: 4vw;
    }

    .supply, .contract {
      flex: 1;
      margin: 0 20px;
      align-items: center;
    }

    .contract{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content:center;
      max-height: 30vh;
      font-size: 5vw;
    }

    .contract{
      flex-direction:row;
    }
  }

  @media (max-width: 1200px) {
    .third-section {
      display: flex;
      flex-direction: column !important;
      
    }

    /* Move TextContainer into third-section */
    .third-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .text-container {
        margin-top: 20px;
        order: 1;
      }
    }

    .first-section {
      display: none; /* Hide first-section */
    }
  }
  @media (max-width:600px) {
    
    .text-container{
      font-size:24px;
    }
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  color: white;
  margin-bottom: 20px;
`;

const ChartContainer = styled.div`
  position: relative;
  width: clamp(200px, 90vw,400px);
  height: 400px;
  margin-bottom: 40px;
  @media (max-width:500px) {
    
  }
`;

const ChartText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  div {
    color: white;
    font-weight: bold;
    font-size: 32px;
  }
  /* @media (max-width:600px) {
    div{
      font-size:24px;
      transform:translateX(10%);
    }
  } */
`;

const SupplyText = styled.div`
  text-align:left;
  color: white;
  font-size: 32px;
  @media (max-width: 1200px) {
    font-size: 20px;
  }
`;

const ContractContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  background-color: white;
  border-radius: 100px;
  padding: 2px;
  width:539px;
  height:49px;
  @media (max-width:800px) {
    width:80vw;
  }
`;

const ContractInput = styled.input`
  font-size:24px;
  font-family: 'Humanist', sans-serif;
  border: none;
  outline: none;
  flex: 1;
  font-size: 1rem;
`;

const BuyButton = styled.button`
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  padding: 10px 20px;
  background-color: #EE5A02;
  color: white;
  border: none;
  border-radius: 100px;
  font-weight: bold;
  cursor: pointer;  
  transition: background-color 0.3s ease;
  transform:translateX(10%);
  &:hover {
    background-color: #ff7a1b;
  }
`;

const Madnomics = () => {
  return (
    <MadnomicsContainer id='hermynomics'>
      <div className="header-designer-ultra-smart-bb-gg" style={{ marginTop: "130px", marginBottom: "50px" }}>HERMYNOMICS</div>
      <div className='madnomics-container' style={{ marginBottom: "130px" }}>
        <div className='first-section'>
          <TextContainer className="text-container">
            <div style={{ fontSize: "32px" }}>
              LIQUIDITY BURNED
              <br />
              OWNERSHIP REVOKED
            </div>
          </TextContainer>
        </div>
        <div className='second-section'>
          <ChartContainer>
            <img src={chartPicture} />
          </ChartContainer>
        </div>
        <div className='third-section'>
          <SupplyText className='supply'>
            SUPPLY: <br /> 1,000,000,000,000
          </SupplyText>

          <div className='contract'>
            <span>CONTRACT ADDRESS</span>
            <ContractContainer>
              <ContractInput type="text" placeholder="CONTRACT ADDRESS" />
              <BuyButton>Buy</BuyButton>
            </ContractContainer>
          </div>
        </div>
      </div>
    </MadnomicsContainer>
  );
};

export { ContractContainer, ContractInput, BuyButton };
export default Madnomics;

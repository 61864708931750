import React, { useState } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
import TikTokIcon from "./TikTokIcon";
import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-links-container">
        <div className="links">
          <a href="#about">ABOUT</a>
          <a href="#howtobuy">HOW TO BUY</a>
          <a href="#pfp">PFP GENERATOR</a>
          <a href="#hermynomics">HERMYNOMICS</a>
          <a href="#faq">FAQ</a>
        </div>
        <div className="social-media-buttons">
          <a href="https://www.instagram.com/hermygenius" target="_blank" rel="noopener noreferrer" className="social-icon">
            <InstagramIcon />
          </a>
          <a href="https://t.me/HermyCoin" target="_blank" rel="noopener noreferrer" className="social-icon">
            <TelegramIcon />
          </a>
          <a href="https://x.com/HermyCoin" target="_blank" rel="noopener noreferrer" className="social-icon">
            <XIcon />
          </a>
          <a href="https://tiktok.com/@hermycoin7" target="_blank" rel="noopener noreferrer" className="social-icon">
            <TikTokIcon />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import "./App.css";
import Home from "./Components/Home";
import About from "./Components/About";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Marquee from "react-fast-marquee";
import MarqueeComponent from "./Components/MarqueeComponent";
import WhatTheHeck from "./Components/WhatTheHeck";
import Available from "./Components/Available";
import Roadmap from "./Components/Roadmap";
import HowToBuy from "./Components/HowToBuy";
import Jupiter from "./Components/Jupiter";
import PFPGenerator from "./Components/PFPGenerator";
import FAQ from "./Components/FAQ";
import Madnomics from "./Components/Madnomics";
import HermyMovement from "./Components/HermyMovement";
import SwapComponent from "./Components/SwapComponent";
import TokenPraceIncreaseIn from "./Components/TokenPraceIncreaseIn";
import Features from "./Components/Features";
function App() {
  return (
    <div className="App">
      <SwapComponent style={{ zIndex: "9999" }} />
      <Navbar />
      <Home />
      <Marquee autoFill="true" pauseOnHover="true" speed={100} style={{ borderTop: "4px solid #fff", zIndex: "0" }}>
        <MarqueeComponent />
      </Marquee>
      <About />
      <Features />
      <WhatTheHeck />
      <Available />
      <Roadmap />
      <HowToBuy />
      <TokenPraceIncreaseIn />
      <PFPGenerator />
      <Madnomics />
      <HermyMovement />
      <FAQ />
      <Marquee autoFill={true} pauseOnHover={true} speed={100} style={{ borderTop: "4px solid #fff", zIndex: "0" }}>
        <MarqueeComponent />
      </Marquee>

      <Footer />
    </div>
  );
}

export default App;

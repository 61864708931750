import React from "react";
import "./HermyMovement.css";
import InstagramIcon from "../Assets/icons/instagram.svg";
import XIcon from "../Assets/icons/x.svg"
import TelegramIcon from "../Assets/icons/telegram.svg"
import TikTokIcon from "../Assets/icons/tiktok.svg";
const HermyMovement = () => {
    return (
        <div className="hermy-movement">
            <div className="empty-block" />
            <div className="hermy-content">
                <div>BE PART OF THE $HERMY MOVEMENT</div>
                <br />
                <p style={{ alignSelf: "center" }}>
                    JOIN A COMMUNITY OF LUXURY LOVERS AND CRYPTO ENTHUSIASTS REDEFINING THE GAME.
                    STEP UP YOUR CRYPTO JOURNEY WITH $HERMY, WIN BIG, AND EMBRACE THE FUTURE.                </p>
                <div className="social-icons">
                    <a href="https://instagram.com/hermygenius" aria-label="Instagram">
                        <img src={InstagramIcon} />
                    </a>
                    <a href="https://t.me/HermyCoin" aria-label="Telegram">
                        <img src={XIcon} />
                    </a>
                    <a href="https://x.com/HermyCoin" aria-label="X">
                        <img src={TelegramIcon} />
                    </a>
                    <a href="https://tiktok.com/@hermycoin7" aria-label="TikTok">
                        <img src={TikTokIcon} />
                    </a>
                </div>
            </div>
            <div className="empty-block" />
        </div>
    );
};

export default HermyMovement;

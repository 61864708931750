import React, { useState, useEffect } from 'react';
import frog from '../Assets/froglifting.png';
import styled from 'styled-components';


const TokenPraceIncreaseIn = () => {
    // Define the target date (e.g., 3 days from now)
    const targetDate = new Date('2024-10-27T00:00:00'); // Example target date
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        // Clear the interval when the component unmounts
        return () => clearInterval(timer);
    }, []);

    function calculateTimeLeft() {
        const now = new Date();
        const difference = targetDate - now;

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    }

    return (
        <Container>
            <Row>
                <ImageContainer>
                    <FrogImage src={frog} alt="Frog Lifting" />
                </ImageContainer>
                <ContentWrapper>
                    TIME TO PUMP<br />
                    $HERMY
                    {/* <div className="header-designer-ultra-smart-bb-gg">BUY $HERMY TOKENS NOW:</div>
                    <ContentContainer>
                        <Subtitle>TOKEN PRACE INCREASE IN</Subtitle>
                        <Timer>
                            <TimerBox>
                                <TimerValue>{timeLeft.days || '00'}</TimerValue><br />
                                <span>DAYS</span>
                            </TimerBox>
                            <TimerBox>
                                <TimerValue>{timeLeft.hours || '00'}</TimerValue><br />
                                <span>HOURS</span>
                            </TimerBox>
                            <TimerBox>
                                <TimerValue>{timeLeft.minutes || '00'}</TimerValue><br />
                                <span>MINUTES</span>
                            </TimerBox>
                            <TimerBox>
                                <TimerValue>{timeLeft.seconds || '00'}</TimerValue><br />
                                <span>SECONDS</span>
                            </TimerBox>
                        </Timer>
                        <p style={{ color: "white", fontFamily: "Inter" }}>USD raised: $1,434,434.00 / 52,222,222.00</p>
                        <Progress>
                            <ProgressBar />
                        </Progress>
                        <ContractContainer>
                            <ContractInput type="text" placeholder="CONTRACT ADDRESS" />
                        </ContractContainer>
                </ContentContainer> */}
                </ContentWrapper>
            </Row>
        </Container >
    );
};

export default TokenPraceIncreaseIn;

const ContractContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  background-color: white;
  border-radius: 100px;
  padding: 2px;
  
  width:539px;
  height:49px;
  @media (max-width:800px) {
    width:90vw;
  }
`;

const ContractInput = styled.input`
  
  font-size:24px;
  font-family: 'Humanist', sans-serif;
  border: none;
  outline: none;
  flex: 1;
  font-size: 1rem;
`;

const BuyButton = styled.button`
  
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  padding: 10px 20px;
  background-color: #EE5A02;
  color: white;
  border: none;
  border-radius: 100px;
  font-weight: bold;
  cursor: pointer;  
  transition: background-color 0.3s ease;
  transform:translateX(10%);
  &:hover {
    background-color: #ff7a1b;
  }
`;

// Styled components
const Container = styled.div`

    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    margin: 5vh 20px;
    text-align: center;
    padding: 50px 0;
    @media (max-width: 800px) {
        .header-designer-ultra-smart-bb-gg{
            font-size:32px;
        }
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    flex-basis: 70%;
    width: 100%; /* Ensure the container takes the full width */
    
     /* You can adjust this value to control the container width */
    transform:translateY(10%);
    @media (max-width: 800px) {
        justify-content: center;
        transform:translateY(0);
    }
`;

const FrogImage = styled.img`
    width: 40vw; /* Set a fixed width for the image */
    height: auto; /* Maintain aspect ratio */
    @media (max-width: 800px) {
        width: 40vh;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    
    justify-items: center;
    align-items: center;

    color:white;
    font-size:48px;
    text-shadow: 4px 4px black;
`;

const ContentContainer = styled.div`
    min-width: 100%;
    padding: 20px 20px 80px 20px;
    border-radius: 30px;
    box-shadow: 4px 4px 0px 0px black;
    background-color: #EE5A02;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 800px) {
        flex-basis: 100%;
        min-width: 95vw;
        width:95vw;
    }
`;

const Title = styled.h1`
    color: white;
    font-size: 64px;
    margin-bottom: 20px;
    @media (max-width: 800px) {
        font-size: 5vw;
    }
`;

const Subtitle = styled.h2`
    color: white;
    font-size: 64px;
    margin-bottom: 30px;
    font-size: 4vw;
    @media (max-width: 800px) {
        font-size: 5vw;
    }
`;

const Timer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`;

const TimerBox = styled.div`
    background-color: white;
    padding: 7px 14px;
    margin: 0 5px;
    border-radius: 5px;
    @media (max-width:800px) {
        font-size: 0.75em;
    }
`;

const TimerValue = styled.span`
    color: #893707;
    font-size: 2em;
    font-weight: bold;
    @media (max-width:800px) {
        font-size: 1.5em;
    }
`;

const Progress = styled.div`
    height: 20px;
    background-color: #ddd;
    border-radius: 35px;
    position: relative;
    margin-bottom: 20px;
    width: 100%;
`;

const ProgressBar = styled.div`
    width: 75%;
    background-color: white;
    height: 100%;
    border-radius: 35px;
`;

import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about.PNG";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { FiArrowRight } from "react-icons/fi";
import './About.css'
import styled from "styled-components";

const About = () => {
  return (
    <div className="about-section-container" id="about">
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <div className="header-designer-ultra-smart-bb-gg">ABOUT <br></br><span style={{ fontSize: "30px", fontFamily: "Kids" }}>HERMY COIN</span></div>
        <p style={{ fontStyle: "normal" }}>
          Hermy is the first memecoin to combine luxury and crypto! Invest, have fun, and get rewarded — with chances to win luxury items or buy them at wholesale prices on our marketplace.</p>
        <div className="about-buttons-container">
          <div class="buy-button-container">
            <a href="#" class="buy-button2">BUY $HERMY</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;